var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"dashboard__container"},[_c('div',{staticClass:"dashboard__container--header"},[_c('h1',[_vm._v("Jobs")]),_c('router-link',{staticClass:"color--text",attrs:{"to":{name: 'addjob'}}},[_c('button',{staticClass:"btn btn__flat"},[_c('i',{staticClass:"fas fa-plus fa-2x"})])])],1),_c('div',{staticClass:"dashboard__container--body"},[(!_vm.jobs || _vm.jobs.length == 0)?_c('Loader'):_vm._e(),(_vm.jobs && _vm.jobs.length >= 1)?_c('div',{staticClass:"flex pt-3"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.jobs,"search-options":{
            enabled: true,
            placeholder: 'Search this table',
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 10,
          }},on:{"on-row-click":_vm.onRowClick}})],1):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }